import 'bootstrap/dist/js/bootstrap';
import $ from 'jquery';
import ModulePreloader from './modules/modulePreloader';
import Homepage from './pages/homepage';
import ModuleNavigation from './modules/moduleNavigation';

$(document).ready(function () {
  const modulePreloader = new ModulePreloader();
  modulePreloader.init();

  const moduleNavigation = new ModuleNavigation();
  moduleNavigation.init();

  const homepage = new Homepage();
  homepage.init();
});
